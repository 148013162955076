<template>
  <v-app>
    <v-main>
      <div v-if="locked" style="margin:25px;max-width: 200px">
        <v-card width="200px" height="344px" style="padding: 10px">
          <v-card-title style="margin:5px">{{ warnGif ? "Error" : "Authentication" }}</v-card-title>
          <v-img v-if="!warnGif" src="https://c.tenor.com/DKSpwZW0AcsAAAAM/loading-money.gif" height="180px" width="180px"></v-img>
          <v-img v-else src="https://media3.giphy.com/media/077i6AULCXc0FKTj9s/giphy.gif" height="180px" width="180px"></v-img>
          <v-text-field :disabled="warnGif" v-model="key" label="PIN Code" @keydown="checkKey"></v-text-field>
        </v-card>
      </div>
      <div v-if="!locked" style="margin-left: 25px; margin-top:25px">
        <v-card width="250px" height="600px">
          <v-card-title>Logitech Remote</v-card-title>
          <v-img src="./assets/logitech.png" style="z-index: 25" height="536px"></v-img>
          <template v-if="!locked">
            <v-btn class="btn" icon style="top: 105px; left: 30px;"
                   @click="sendSignal('power')"></v-btn>
            <v-btn class="btn" icon style="top: 105px; left: 100px;"
                   @click="sendSignal('mute')"></v-btn>
            <v-btn class="btn" icon style="top: 105px; left: 170px;"
                   @click="sendSignal('mode_surround')"></v-btn>
            <v-btn class="btn" icon style="top: 170px; left: 30px;"
                   @click="sendSignal('vol_down')"></v-btn>
            <v-btn class="btn" icon style="top: 170px; left: 100px;"
                   @click="sendSignal('channel_lvl')"></v-btn>
            <v-btn class="btn" icon style="top: 170px; left: 170px;"
                   @click="sendSignal('vol_up')"></v-btn>
            <v-btn class="btn" icon style="top: 235px; left: 30px;" @click="sendSignal('aux')"></v-btn>
            <v-btn class="btn" icon style="top: 235px; left: 170px;"
                   @click="sendSignal('rca')"></v-btn>

            <v-btn class="btn" icon style="top: 300px; left: 30px;"
                   @click="sendSignal('fm_radio')"></v-btn>
            <v-btn class="btn" icon style="top: 300px; left: 100px;"
                   @click="sendSignal('bluetooth')"></v-btn>
            <v-btn class="btn" icon style="top: 300px; left: 170px;"
                   @click="sendSignal('sd_usb')"></v-btn>

            <v-btn class="btn" icon style="top: 365px; left: 30px;"
                   @click="sendSignal('down')"></v-btn>
            <v-btn class="btn" icon style="top: 365px; left: 100px;"
                   @click="sendSignal('play')"></v-btn>
            <v-btn class="btn" icon style="top: 365px; left: 170px;" @click="sendSignal('up')"></v-btn>
          </template>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},

  data: () => ({
    locked: true,
    warnGif: false,
    key: "",
  }),

  created() {
    if (this.$cookies.get("api-key") != null)
      this.locked = false;
  },

  methods: {


    checkKey: function (event) {
      if (event.key === 'Enter') {
        this.$http.get("ir/validateIrPw?key=" + event.target.value).then(res => {
          if (res.data === false) {
            this.warnGif = true;
            let ctx = this;
            setTimeout(function () {
              ctx.warnGif = false
            }, 3540)
            return
          }
          this.locked = false;
          this.$cookies.set("api-key", res.data)
        })
        event.target.value = ""
      }
    },

    sendSignal: function (command) {
      this.$http.get("ir/exec?cmd=" + command + "&key=" + this.$cookies.get("api-key")).then(res=>{
        if (res.status === 205) {
          this.$cookies.remove("api-key")
          this.locked = true;
        }
      }).catch()
    }
  }
};
</script>
<style>

.btn {
  position: absolute !important;
  height: 55px !important;
  width: 55px !important;
  z-index: 35;
}

</style>
