import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCookies from "vue-cookies";
Vue.config.productionTip = false

import ApiService from "./services/api.service";

ApiService.init("https://homecontrol.andimajore.de/")
// ApiService.init("localhost:8090/")
Vue.prototype.$http = ApiService;
Vue.use(VueCookies)
Vue.$cookies.config('30d')

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
